import { RouteConfig } from "vue-router";

const ZoomRoutes: Array<RouteConfig> = [
  {
    path: "/call_center/sms_list",
    name: "Sms List",
    component: () => import("@/views/Zoom/SmsList.vue"),
    meta: {
      requiresLogged: true,
    },
  },
  {
    path: "/call_center/call_list",
    name: "Call List",
    component: () => import("@/views/Zoom/CallList.vue"),
    meta: {
      requiresLogged: true,
    },
  },
  {
    path: "/call_center/webhooks",
    name: "Webhooks",
    component: () => import("@/views/Zoom/Webhooks.vue"),
    meta: {
      requiresLogged: true,
    },
  },
];

export default ZoomRoutes;
